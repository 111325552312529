import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import CTA from "../../cta"
import * as styles from "./style.module.scss"

const Header = () => {
  const {
    sanityFaqPage: { header: data },
  } = useStaticQuery(graphql`
    query FaqHeaderQuery {
      sanityFaqPage {
        header {
          firstColumn {
            _rawColumnContent(resolveReferences: { maxDepth: 5 })
          }
          secondColumn {
            _rawColumnContent(resolveReferences: { maxDepth: 5 })
          }
          reverse
        }
      }
    }
  `)

  return (
    <div className={styles.section}>
      <div className={styles.container}>
        <CTA
          firstColumn={data.firstColumn._rawColumnContent}
          secondColumn={data.secondColumn._rawColumnContent}
          mobileReverse={data.reverse}
        />
      </div>
    </div>
  )
}

export default Header
