import React from "react"
import LayOut from "../components/layout"
import Header from "../components/faqs/header/"
import DropDowns from "../components/faqs/dropdowns/"
import SEO from "../components/faqs/seo"

const Faqs = () => {
  return (
    <LayOut>
      <SEO />
      <div>
        <Header />
        <DropDowns />
      </div>
    </LayOut>
  )
}

export default Faqs
