import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Collapse } from "antd"
import BlocksContent from "../../blocksContent"
import * as styles from "./style.module.scss"

const { Panel } = Collapse

const Dropdowns = () => {
  const {
    sanityFaqPage: {
      faqs: { dropdowns: data },
    },
  } = useStaticQuery(graphql`
    query faqsQuery {
      sanityFaqPage {
        faqs {
          dropdowns {
            _key
            title
            description {
              _rawColumnContent(resolveReferences: { maxDepth: 5 })
            }
          }
        }
      }
    }
  `)

  return (
    <div className={styles.section}>
      <div className={styles.container}>
        <Collapse bordered={false} defaultActiveKey={["1"]}>
          {data.map((faq, index) => (
            <Panel key={index + 1} header={faq.title}>
              <BlocksContent blocks={faq.description._rawColumnContent} />
            </Panel>
          ))}
        </Collapse>
      </div>
    </div>
  )
}

export default Dropdowns
